
























import Vue from 'vue';
import { mapMutations, mapState, mapActions } from 'vuex';
import RepositoryFactory from '@/services/RepositoryFactory';
import IBoxesRepository, { IBox } from '@/services/Repositories/Boxes/types';
import IBoxShippingsRepository from '@/services/Repositories/BoxShippings/types';
import IMerchantRepository from '@/services/Repositories/merchantRepository/types';
import DefaultLayout from '@/layouts/Default.vue';
import BoxesTable from '@/components/Tables/BoxesTable.vue';
import { EnumFilterDrawerIcons } from '@/store/modules/globals/types';
import TokenService from '@/services/TokenService';

const tokenService = new TokenService();

const factory = new RepositoryFactory();

export default Vue.extend({
  name: 'BoxOverview',

  data() {
    return {
      selectedStatus: 0,
      selectedMerchant: 'all',
      merchants: [] as string[],
      statusCount: {},
      search: '',
      expanded: [],
      headers: [
        {
          text: 'status',
          align: 'start',
          value: 'status',
        },
        {
          text: `${this.$t('box-overview.reboxNo')}`,
          value: 'code',
        },
        {
          text: `${this.$t('box-overview.orderNo')}`,
          value: 'currentBoxShipping.order.orderNr',
        },
        {
          text: `${this.$t('box-overview.customerMail')}`,
          value: 'currentBoxShipping.order.custEmail',
        },
        {
          text: `${this.$t('box-overview.customerAddress')}`,
          value: 'customerAddress',
        },
        {
          text: `${this.$t('box-overview.shipping-date')}`,
          value: 'shippingDate',
        },
        {
          text: `${this.$t('box-overview.days-since-last-change')}`,
          value: 'daysSinceChange',
        },
        {
          text: '',
          value: 'data-table-expand',
          align: 'end',
        },
      ],
      filters: [
        {
          label: 'boxOverviewFilters.all',
          value: 0,
          icon: '$box',
          count: 0,
          key: 'all',
        },
        {
          label: 'boxOverviewFilters.REGISTERED',
          value: 'REGISTERED',
          icon: '$shoppingBag',
          count: 0,
          key: 'registered',
        },
        {
          label: 'boxOverviewFilters.PREPARED_FOR_SHIPMENT',
          value: 'PREPARED_FOR_SHIPMENT',
          icon: '$bookmark',
          count: 0,
          key: 'preparedForShipment',
        },
        {
          label: 'boxOverviewFilters.SHIPPED_TO_CUSTOMER',
          value: 'SHIPPED_TO_CUSTOMER',
          icon: '$arrowRight',
          count: 0,
          key: 'shippedToCustomer',
        },
        {
          label: 'boxOverviewFilters.DELIVERED_TO_CUSTOMER',
          value: 'DELIVERED_TO_CUSTOMER',
          icon: '$smile',
          count: 0,
          key: 'deliveredToCustomer',
        },
        {
          label: 'boxOverviewFilters.SHIPPED_TO_MERCHANT',
          value: 'SHIPPED_TO_MERCHANT',
          icon: '$arrowLeft',
          count: 0,
          key: 'shippedToMerchant',
        },
        {
          label: 'boxOverviewFilters.DELIVERED_TO_MERCHANT',
          value: 'DELIVERED_TO_MERCHANT',
          icon: '$shoppingBag',
          count: 0,
          key: 'deliveredToMerchant',
        },
        {
          label: 'boxOverviewFilters.DAMAGED',
          value: 'DAMAGED',
          icon: '$trash',
          count: 0,
          key: 'damaged',
        },
      ],
    };
  },
  components: {
    DefaultLayout,
    BoxesTable,
    FilterDrawer: () => import('@/components/ui/FilterDrawer.vue'),
    BoxesStatusFilter: () => import('@/components/filters/BoxesStatusFilters.vue'),
  },
  computed: {
    ...mapState('globals', ['loader', 'token', 'searchBarInput']),
    ...mapState('boxes', ['boxesQuery', 'boxes']),
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    breakpoint(): boolean {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    boxesRepository(): IBoxesRepository {
      return factory.get('boxes') as IBoxesRepository;
    },
    boxShippingRepository(): IBoxShippingsRepository {
      return factory.get('boxShippings') as IBoxShippingsRepository;
    },
    merchantRepository(): IMerchantRepository {
      return factory.get('merchant') as IMerchantRepository;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_FILTERDRAWER_ICON', 'SET_SEARCH_INPUT']),
    ...mapMutations('boxes', ['SET_BOXES_MERCHANT']),
    ...mapActions('boxes', ['getBoxes']),
    async getMerchants() {
      const role = tokenService.userRoles(this.token);
      const merchantHeader = this.headers.find(({ text }) => text === 'merchant');
      const exist = this.headers.find(({ text }) => text === this.$t('box-overview.customer'));
      if (role.includes('ROLE_REBOX_ADMIN')) {
        const data = await (this as any).merchantRepository.getMerchants();
        const allMerchants = data.data['hydra:member'];
        if (!merchantHeader) {
          this.headers.push({
            text: 'merchant',
            value: 'merchant.name',
          });
        }
        const allObject = { id: 'all', name: 'all' };
        this.merchants = [allObject, ...allMerchants];
      } else if (!exist) {
        this.headers.splice(3, 0, {
          text: `${this.$t('box-overview.customer')}`,
          value: 'customer',
        });
      }
    },
    getAdditionalFilter() {
      const role = tokenService.userRoles(this.token);
      if (role.includes('ROLE_REBOX_ADMIN')) {
        const filter = {
          label: 'boxOverviewFilters.GENERATED',
          value: 'GENERATED',
          icon: 'mdi-clipboard-text',
          count: 0,
          key: 'generated',
        };
        this.filters.splice(2, 0, filter);
      }
    },
    async getStatusCount() {
      const data = await this.boxesRepository.getStatusCount();
      this.statusCount = data.data;
      for (const item of this.filters) {
        for (const [key, value] of Object.entries(this.statusCount)) {
          if (item.key === key) {
            item.count = value as number;
          }
        }
      }
    },
    shouldClearBoxesQuery(value: any, oldValue: any) {
      if (
        value.status !== oldValue.status
        || value.page !== oldValue.page
        || value.merchant !== oldValue.merchant
      ) {
        return true;
      }
      return false;
    },
  },
  async created() {
    this.SET_SEARCH_INPUT('');
    this.SET_BOXES_MERCHANT('');
    this.getAdditionalFilter();
    await this.getBoxes();
    await this.getMerchants();
    await this.getStatusCount();
  },
  mounted() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    if (this.isMobile) {
      this.SET_FILTERDRAWER_ICON(EnumFilterDrawerIcons.filter);
    }
    this.$headerService.config({
      title: 'header.overview',
      icons: { icon: icons.processes, action: actions.processes },
    });
  },
  beforeDestroy() {
    this.SET_FILTERDRAWER_ICON(EnumFilterDrawerIcons.noIcon);
  },
  watch: {
    selectedMerchant: {
      async handler(value: string) {
        if (value === 'all') {
          this.SET_BOXES_MERCHANT('');
        } else {
          this.SET_BOXES_MERCHANT(value);
        }
        await this.getBoxes();
      },
    },
    selectedStatus: {
      async handler() {
        await this.getBoxes();
      },
    },
    isMobile: {
      handler(newVal: boolean) {
        if (newVal) {
          this.SET_FILTERDRAWER_ICON(EnumFilterDrawerIcons.filter);
        } else {
          this.SET_FILTERDRAWER_ICON(EnumFilterDrawerIcons.noIcon);
        }
      },
      immediate: true,
    },
    boxesQuery: {
      handler(value, oldValue) {
        if (this.shouldClearBoxesQuery(value, oldValue)) {
          this.getBoxes();
          this.getMerchants();
        }
      },
    },
  },
});
