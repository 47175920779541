var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loader,"items-per-page":_vm.boxesQuery.itemsPerPage,"page":_vm.boxesQuery.page,"footer-props":_vm.footerOptions,"server-items-length":_vm.boxesAmount,"options":_vm.options},on:{"click:row":_vm.redirect,"update:options":[function($event){_vm.options=$event},_vm.updateOptions],"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!item.escalationLevel},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(_vm.getColorOfStatus(item))},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(("boxOverviewFilters." + (item.status))))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getStatusTooltip(item)))])])]}},{key:"item.daysSinceChange",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.daysSinceChange(item.updatedAt))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }